@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Segoe+UI:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  @apply leading-[normal] m-0;
}
*,
::before,
::after {
  border-width: 0;
}

@keyframes slide-in-left {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
